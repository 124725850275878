import { getSchemaPath, unwrapNullable } from './typebox';
import { genFieldTitle } from './utils/formats';
import { assert } from './vendor/@sindresorhus/is';
import { R } from './vendor/remeda';
import { type StaticDecode, type TObject, type TSchema } from '@sinclair/typebox';
import { Value } from '@sinclair/typebox/value';

class SchemedBrand {
  readonly #schemed = Symbol('schemed');
}

export type S<T = unknown> = T & SchemedBrand;

export type Unschemed<T extends object> = Omit<T, '#schemed'>;

declare module '@vue/reactivity' {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  export interface RefUnwrapBailTypes {
    schemedType: SchemedBrand;
  }
}

const objToSchemaMap = new WeakMap<object, TSchema>();

export const extractSchema = (obj: S) => {
  const schema = objToSchemaMap.get(toRaw(obj));
  if (!schema) throw new Error('Missing schema for schemed object!');

  return schema as TObject;
};

export const shallowSchemed = <T extends TSchema>(schema: T, obj: StaticDecode<T>) => {
  objToSchemaMap.set(toRaw(obj) as object, schema);

  return obj as S<StaticDecode<T>>;
};

export type DeepSchemed<T> = T extends unknown[]
  ? { [K in keyof T]: DeepSchemed<T[K]> }
  : T extends Record<string, unknown>
    ? S<{ [K in keyof T]: DeepSchemed<T[K]> }>
    : T;

export const schemed = <T extends TSchema>(schema: T, value: StaticDecode<T>) => {
  if (R.isArray(value)) {
    for (const v of value) {
      if (!R.isArray(v) && !R.isPlainObject(v)) continue;
      schemed(schema.items, v);
    }
  } else if (R.isPlainObject(value)) {
    shallowSchemed(schema, value);
    for (const [k, v] of R.entries(value)) {
      if (!R.isArray(v) && !R.isPlainObject(v)) continue;
      const s = getSchemaPath(schema, k);
      if (s) schemed(s, v);
    }
  }

  return value as SchemedDecode<T>;
};

export type SchemedDecode<T extends TSchema> = DeepSchemed<StaticDecode<T>>;

export const schemedDecode = <T extends TSchema>(schema: T, value: unknown) => {
  const decoded = Value.Decode(schema, value);
  const res = schemed(schema, decoded);
  return res;
};

export const genSchemedTitle = <T extends Record<string, unknown>>(obj: S<T>, path: keyof T & string) => {
  const s = getSchemaPath(extractSchema(obj), path);
  assert.truthy(s);
  return genFieldTitle(path, unwrapNullable(s));
};
